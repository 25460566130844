<template>
  <b-modal
    id="modal-delete-cashbook"
    ref="refModalDeleteCashbook"
    title="Xóa sổ quỹ"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Xác nhận xóa'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-alert
        show
        variant="danger"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="AlertTriangleIcon"
          />
          <span class="ml-25">Vui lòng chọn sổ quỹ mới cho các phiếu thu, phiếu chi gắn với các sổ quỹ sắp bị xóa.</span>
        </div>
      </b-alert>
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Danh sách sổ quỹ bị xóa
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <cashbook-table :data="cashbooks" />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Sổ quỹ tiếp nhận dữ liệu
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="liquidDate"
              rules="required"
            >
              <select-cashbook
                v-model="itemLocal.cashbook"
                required
                :ignore-ids="cashbooks.map(obj => obj.id)"
                :default-value="itemLocal.cashbook"
                label="Chọn sổ quỹ"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectCashbook from '@/views/components/SelectCashbook.vue';
import useDeleteCashbookModal from './useDeleteCashbookModal';
import CashbookTable from './CashbookTable.vue';

export default {
  components: {
    BModal,
    BAlert,
    BRow,
    BCol,

    BForm,
    ValidationObserver,
    ValidationProvider,

    CashbookTable,
    SelectCashbook,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      cashbooks,
      onSubmit,
    } = useDeleteCashbookModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      cashbooks,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
